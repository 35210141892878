function GuideBook() {
    return (
        <>

            <section className="feature section pt-0 mt-5">
                <div className="container">
                    <div className="col-md-12">

                        <iframe src="images/pdf/GUIDE BOOK YISF 2023 BARU.pdf" width="100%" height="800"></iframe>
                        
                    </div>
                </div>
            </section>

        </>
    )
}

export default GuideBook;