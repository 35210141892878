function Certificate(){
    return (

        <>
            <div className="mt-5">
                <div className="container">
                    <h2 className="text-center mb-3">Certificate</h2>
                    <div className="col-md-12">
                        
                        <div className="content-curation mt-3">
                            <div className="row">

                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="" alt=""></img>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Certificate